<template>
  <b-card title="Tambah Banner Home ➕">
    <b-form @submit.prevent>
      <b-row>
        <!-- first name -->
        <b-col cols="12">
          <b-form-group
            label="Nama Banner"
            label-for="v-nama-banner"
          >
            <b-form-input
              id="v-nama-banner"
              v-model="data.nama_banner"
              placeholder="Nama Banner"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="File Banner (MASUKKAN FILE DENGAN RASIO 16:9)"
            label-for="v-file-banner"
          >
            <b-form-file
              id="v-file-banner"
              v-model="data.file_banner"
              placeholder="File Banner"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="saveData()"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormFile,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      data: {
        nama_banner: '',
        file_banner: '',
      },
    }
  },
  methods: {
    saveData() {
      const data = new FormData()
      data.append('nama_banner', this.data.nama_banner)
      data.append('file_banner', this.data.file_banner)
      this.$http.post('/banner', data).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'banner-index' })
          }, 500)
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
